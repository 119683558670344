module.exports = [
  {
    name: '舒伯职业价值观测评量',
    name2: 'Schuber professional values scale',
    key: 'Personality_color_test',
    option: ['A', 'B', 'C', 'D', 'E'],
    description: `职业价值观是指人生目标和人生态度在职业选择方面的具体表现，也就是一个人对职业的认识和态度以及他对职业目标的追求和向往。舒伯职业价值观测试分为三个维度：

      内在价值观（和职业本身相关的因素）；
      
      外部价值观（和职业相关的外部因素）；
      
      薪水报酬（包括待遇福利等可见收入）
      
      这三个大维度下又包含了13个因素：利他主义；美感；智力刺激；成就感；独立性；社会地位；管理；经济报酬；社会交际；安全感；舒适；人际关系；变异性或追求新意。`,
    EnglishText: `Organizations and facilitators choose the DISC assessment because it is easy to administer, user-friendly and a powerful tool in a wide range of applications.`,
    background: `舒伯职业价值观测试(WVI) 由心理学家舒伯在1970年完成编制，用于测评个体的职业价值观，表明个体对职业的认识、接受、追求和向往的目标。是从业者的职业期望，职业价值观决定了员工在岗的工作态度和工作效率，也反映了个体工作满意度。无论是个人职业规划，还是企业招聘，舒伯职业价值观测试都是重要的测评工具。`,
    questionList: [
      {
        question: '能参与救灾济贫工作',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能经常欣赏完美的工艺作品',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能经常尝试新的构想',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '必须花精力去深入思考',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '在职责范围内有充分自由',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可以经常看到自己的工作成果',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能在社会扮演更重要的角色',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能知道别人如何处理事务',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '收入能比相同条件的人高',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能有稳定的收入',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能有清静的工作场所',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '主管善解人意',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能经常和同事一起休闲',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能经常变换职务',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能成为你想成为的人',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能帮助贫困和不幸的人',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能增添社会的文化气息',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可以自由地提出新颖的想法',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '必须不断学习才能胜任',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作不受他人干涉',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '我觉得自己的辛苦没有白费',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能使你更有社会地位',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能够分配调整他人的工作',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能常常加薪',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '生病时能有妥善照顾',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作地点光线通风好',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '有一个公正的主管',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能与同事建立深厚的友谊',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作性质常会变化',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能实现自己的理想',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能够减少别人的苦难',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能运用自己的鉴赏力',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '常需构思新的解决方法',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '必须不断地解决新的难题',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能自行决定工作方式',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能知道自己的工作绩效',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能让你觉得出人头地',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可以发挥自己的领导能力',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可使你存下许多钱',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '有好的保险和福利制度',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作场所有现代化设备',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '主管能采取民主领导方式',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '不必和同事有利益冲突',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可以经常变换工作场所',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '常让你觉得如鱼得水人',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能常帮助他人解决困难',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能创作优美的作品',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '常需提出不同的处理方案',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '需对事情深入分析研究',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '可以自行调整工作进度',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作结果受到他人肯定',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能自豪地介绍自己的工作',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能为团体拟定工作计划',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '收入高于其他行业',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '不会轻易地被解雇或裁员',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作场所整洁卫生',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '主管学识和品德让你钦佩',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能够认识很多风趣的伙伴',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '工作内容随时间变化',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
      {
        question: '能充分的发挥自己的专长',
        A: '非常重要',
        B: '比较重要',
        C: '一般',
        D: '较不重要',
        E: '很不重要',
      },
    ],
  },
]
